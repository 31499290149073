<template>
  <v-container fill-height justify-center>
    <div>
      <v-card outlined max-width="350" class="pa-4 text-center" rounded="lg">
        <v-card-text>
          <v-img
            max-height="100"
            contain
            alt="IMSO"
            :src="require('@/assets/logo.png')"
            class="ma-auto"
          />
          <div class="text-h6">Sign in</div>
          <div class="mb-5">with your OPEnE Email</div>
          <v-form ref="loginForm" @submit.prevent>
            <v-text-field
              label="Email"
              v-model="email"
              :rules="[rules.required, rules.email]"
              outlined
              dense
              @keyup.enter="sendOtp()"
            ></v-text-field>
            <v-btn
              depressed
              block
              class="primary text-capitalize"
              @click="sendOtp"
              :loading="loading"
            >
              Next
            </v-btn>
          </v-form>
          <div class="mt-5 text-caption">
            This is the Information Management System of OPEnE. For internal use
            only.
          </div>
        </v-card-text>
        <!-- <div class=" mt-5 text-caption">
         This is an Information Management System of OPEnE. Only for internal use of the organization.
      </div> -->
      </v-card>
      <v-sheet flat max-width="350" color="transparent" class="text-right mt-2">
        <v-btn
          text
          x-small
          color="grey"
          class="text-capitalize"
          link
          href="https://openesrilanka.org/"
          target="blank"
        >
          Help
        </v-btn>
        <v-btn
          text
          x-small
          color="grey"
          class="text-capitalize"
          link
          href="https://openesrilanka.org/"
          target="blank"
        >
          Privacy
        </v-btn>
        <v-btn
          text
          x-small
          color="grey"
          class="text-capitalize"
          link
          href="https://openesrilanka.org/"
          target="blank"
        >
          Terms
        </v-btn>
      </v-sheet>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import { sentOTP } from "@/services/authService";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    email: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+\..+/.test(value) || "Invalid e-mail.";
      },
    },
    loading: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goNext() {
      this.$router.push({ name: "PageAuthOTP" });
    },
    async sendOtp() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        let sentOTPReturn = await sentOTP({ email: this.email });
        this.loading = false;
        if (sentOTPReturn == "success") {
          this.goNext();
        }
      }
    },
  },
};
</script>